import React from "react";
import { SearchBox } from "../SearchBox";
import {
  Hero as StyledHero,
  HeroGrid,
  Heading,
  HeadingIntro,
  Text,
  Search,
} from "./styles";

export const Hero: React.FC = () => (
  <StyledHero>
    <HeroGrid>
      <div>
        <Heading>
          <HeadingIntro>Welcome to</HeadingIntro> Nigel Pugh Motors
        </Heading>
        <Text>
          We make buying your next used car easy.
          <br />
          All come with a multi-point pre-sales inspection and with 12 months
          MOT.
        </Text>
        <Text>
          Call us now on <b>01686 624162</b>
        </Text>
      </div>
      <div>
        <Search>
          <SearchBox />
        </Search>
      </div>
    </HeroGrid>
  </StyledHero>
);
