import React from "react";
import { Card } from "../Card";
import { SearchForm } from "../SearchForm";
import { Box, Heading } from "./styles";

export const SearchBox: React.FC = () => {
  return (
    <Box>
      <Card>
        <Heading>Find your perfect car</Heading>
        <SearchForm isBox />
      </Card>
    </Box>
  );
};
