import styled from "styled-components";
import { Button as _Button } from "../Button";
import { Grid as _Grid } from "../Grid";

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Grid = styled(_Grid)`
  margin-bottom: 60px;
`;

export const Button = styled(_Button)`
  width: 300px;
`;
