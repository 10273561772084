import React from "react";
import { PageProps, graphql } from "gatsby";
import { Layout } from "../components/Layout";
import { Hero } from "../components/Hero";
import { Section } from "../components/Section";
import { SectionHeading } from "../components/SectionHeading";
import { SectionIntro } from "../components/SectionIntro";
import { VehicleCardList } from "../components/VehicleCardList";
import { VehicleCard } from "../components/VehicleCard";
import { ServiceCardList } from "../components/ServiceCardList";
import { ServiceCard } from "../components/ServiceCard";
import { Map } from "../components/Map";
import { formatMileage, formatPrice } from "../utils";

interface HomeProps extends PageProps {
  data: {
    allWordpressWpVehicle: any;
    allWordpressWpService: any;
  };
}

const Home: React.FC<HomeProps> = ({ data }) => (
  <Layout>
    <Hero />
    <Section>
      <SectionHeading>Our latest used cars &amp; trucks</SectionHeading>
      <VehicleCardList>
        {data.allWordpressWpVehicle.edges.map(
          ({ node: { id, title, slug, acf } }) => (
            <VehicleCard
              key={id}
              image={acf.gallery && acf.gallery[0] && acf.gallery[0].source_url}
              title={title}
              description={`${acf.transmission} | ${acf.engine} | ${
                acf.paint
              } | ${formatMileage(acf.mileage)}`}
              price={acf.price > 0 ? formatPrice(acf.price) : "N/A"}
              href={`/vehicles/${slug}`}
            />
          )
        )}
      </VehicleCardList>
    </Section>
    <Section palette="secondary" isCenterAlign>
      <SectionHeading>Our workshop services</SectionHeading>
      <SectionIntro>
        At our Newtown workshop we have been established for over 36 years and
        offer a well appointed three bay facility equipped with modern
        diagnostic equipment.
      </SectionIntro>
      <ServiceCardList showLink>
        {data.allWordpressWpService.edges.map(
          ({ node: { id, title, slug, content } }) => (
            <ServiceCard
              key={id}
              icon="foo.png"
              title={title}
              href={`/services/${slug}`}
            />
          )
        )}
      </ServiceCardList>
    </Section>
    <Section isCenterAlign id="about-us">
      <SectionHeading>About us</SectionHeading>
      <SectionIntro>
        After 7 years of extensive training Nigel started business and traded as
        Nigel Pugh motor engineering, That was 36 years ago. The workshop has
        grown in size over the years and is now equipped with the latest
        diagnostic equipment / air conditioning equipment fuel injection test
        rig and servicing equipment. A small but experienced group of motor
        engineers to carry out servicing, mot’s, diagnostic fault code reading
        on European, and asian cars, servicing of air conditioning systems.
        <br />
        <br />
        He expanded into the sales of cars 10 years later after requests from
        his many customers to provide this added service. After all, the
        customers trusted Nigel to obtain the car and fully check it out before
        he passed it on to them.
        <br />
        <br />
        Nigel has enjoyed the excitement of the Rallying world and has been
        involved in the maintenance of these cars over the years building up an
        extensive knowledge. Nigel is also involved with driving rally cars.
        Today Nigel is still on hand to offer you all those years of experience,
        knowledge and trust when its time to change your car.
      </SectionIntro>
    </Section>
    <Map id="contact-us" />
  </Layout>
);

export const pageQuery = graphql`
  query {
    allWordpressWpService {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
    allWordpressWpVehicle(
      filter: { acf: { sold: { ne: true } } }
      limit: 3
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          acf {
            gallery {
              source_url
            }
            engine
            manufacturer
            mileage
            model
            paint
            price
            transmission
            year
          }
        }
      }
    }
  }
`;

export default Home;
