import styled from "styled-components";

export const Box = styled.div`
  width: 100%;

  @media (min-width: 850px) {
    position: absolute;
    top: -10px;
    right: 0;
    max-width: 420px;
  }
`;

export const Heading = styled.h1`
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 30px;
  letter-spacing: 1.2px;

  @media (min-width: 850px) {
    font-size: 24px;
  }
`;
export const Form = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;

export const CTA = styled.div`
  grid-column: 1 / 3;
  margin-top: 20px;
`;
