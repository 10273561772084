import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import ZoomControl from "mapbox-gl-controls/lib/zoom";
import { Map as StyledMap } from "./styles";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYmdyaWZmaXRoIiwiYSI6ImNrYWJjNTJmZzExczMzMGw1M3Jvc3A0MXEifQ.W7G4Hvxu5ZkUQ8TGKEdmOA";

interface MapProps {
  id?: string;
}

export const Map: React.FC<MapProps> = ({ id }) => {
  const mapRef = useRef();

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapRef.current,
      style: "mapbox://styles/bgriffith/ckablzciq15w11iog9rlxvwc0",
      center: [-3.303, 52.515],
      zoom: 14.0,
      scrollZoom: false,
    });
    map.addControl(new ZoomControl(), "top-right");
    const marker = new mapboxgl.Marker({ color: "#AD2C2C" })
      .setLngLat([-3.3029, 52.51555])
      .addTo(map);
  }, []);

  return <StyledMap id={id} ref={mapRef}></StyledMap>;
};
