import styled from "styled-components";
import { white, red } from "../../styles/colors";

export const Hero = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${red};
  color: ${white};
  box-shadow: inset 0 -3px 10px 5px rgba(0, 0, 0, 0.14);
  padding: 0 15px 40px;

  @media (min-width: 850px) {
    height: 350px;
    padding-bottom: 0;
  }
`;

export const HeroGrid = styled.div`
  width: 100%;
  max-width: 940px;
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 850px) {
    grid-template-columns: repeat(2, minmax(auto, 470px));
  }
`;

export const Heading = styled.h1`
  display: block;
  flex: 1;
  font-family: "Roboto Condensed";
  font-size: 38px;
  font-weight: 900;
  line-height: 1.1;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 20px;

  @media (min-width: 475px) {
    font-size: 50px;
  }

  @media (min-width: 850px) {
    margin-top: 10px;
  }
`;

export const HeadingIntro = styled.span`
  display: block;
  font-size: 20px;

  @media (min-width: 475px) {
    font-size: 26px;
  }
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 1.6;
  font-weight: 300;
  margin-bottom: 10px;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.9);

  @media (min-width: 475px) {
    font-size: 18px;
  }

  b {
    font-weight: 400;
    color: ${white};
  }

  + p {
    margin-top: 20px;
  }
`;

export const Search = styled.div`
  position: relative;
  top: 20px;

  @media (min-width: 850px) {
    top: 0;
  }
`;
