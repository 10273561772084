import React from "react";
import { Link } from "gatsby";
import { List, Grid, Button } from "./styles";

export const VehicleCardList = ({ children }) => (
  <List>
    <Grid>{children}</Grid>
    <Link to="/our-cars">
      <Button palette="secondary">View more vehicles</Button>
    </Link>
  </List>
);
